
import { defineComponent, ref, Ref, onMounted, computed, inject } from "vue";
import { useI18n } from "vue-i18n";
import { useForm, useField, useValidateField } from "vee-validate";
import { useStore } from "../store";
import {
  AcademicTitle,
  ContractType,
  Currency,
  RegisterContract,
  Specializations,
  SpecializationsStatus,
} from "../../types";
import {
  deepIterate,
  dataUrlToFile,
  isValidNip,
  isValidPostcode,
  fileToBase64,
} from "@hd2/common/src/utils";
import {
  get,
  set,
  reduce,
  pickBy,
  findKey,
  map,
  mapValues,
  keyBy,
} from "lodash";
import Upload from "@hd2/common/src/components/Upload.vue";
import { message } from "ant-design-vue";
import moment, { Moment } from "moment";
import { useShowErrors } from "@hd2/common/src/composable/useShowErrors";
import { useRouter } from "vue-router";
import {
  PlusCircleFilled,
  CloseOutlined,
  InfoCircleFilled,
} from "@ant-design/icons-vue";
import * as yup from "yup";
import { resolveYupValue } from "../utils";
import { TestOptions } from "yup";
import { AxiosStatic } from "axios";

type AbstractContracType = "B2B" | "CONTRACT_FOR_SERVICES";

type RegisterContractExt = Required<
  Omit<RegisterContract, "pwz" | "insurance" | "specializations">
> & {
  pwz: {
    file: Array<File>;
    number: string;
  };
  abstractContractType: AbstractContracType;
  insurance: {
    file: Array<File>;
    dateFrom: Moment;
    dateTo: Moment;
    amount: Omit<Currency, "value"> & { value: string };
  };
  specializations: Array<{
    id: Specializations[keyof Specializations] | undefined;
    status: SpecializationsStatus | undefined;
  }>;
};

export const RegisterContractComponent = defineComponent({
  components: {
    Upload,
    PlusCircleFilled,
    CloseOutlined,
    InfoCircleFilled,
  },
  setup() {
    const { t } = useI18n();
    const http = inject("http") as AxiosStatic;
    const store = useStore();
    const { showErrors } = useShowErrors();
    const router = useRouter();

    const pwzRequired: Ref<boolean> = ref(true);
    let specializations: Specializations = {};
    const specializationsOptions: Ref<Array<Specializations>> = ref([]);

    const schema = yup.object({
      abstractContractType: yup.string().required(t("REGISTER.FIELD_REQUIRED")),
      contractType: yup
        .mixed()
        .test(
          "is-b2b",
          t("REGISTER.FIELD_REQUIRED"),
          (value: string, context) => {
            const req = resolveYupValue(context, "abstractContractType");
            if (req && req == "B2B") {
              return value ? true : false;
            } else {
              return true;
            }
          }
        ),
      academicTitle: yup.string().required(t("REGISTER.FIELD_REQUIRED")),
      pwz: yup.object({
        number: yup
          .mixed()
          .test(
            "is-pwz-required",
            t("REGISTER.FIELD_REQUIRED"),
            (value: string, context: TestOptions["options"]) => {
              let pwzAvailable = false;
              const req = resolveYupValue(context, "specializations");
              if (req) {
                for (let i = 0; i < req.length; i++) {
                  if (
                    specializations["DIETETICS"] === req[i].id ||
                    specializations["PSYCHOLOGY"] === req[i].id ||
                    specializations["OBSTETRICS"] === req[i].id
                  ) {
                    pwzRequired.value = false;
                    pwzAvailable = true;
                  } else {
                    pwzRequired.value = true;
                    if (value?.length > 0) pwzAvailable = true;
                    else pwzAvailable = false;
                    break;
                  }
                }
              }
              return pwzAvailable;
            }
          ),
      }),
      authorityForMedicalCerts: yup
        .bool()
        .required(t("REGISTER.FIELD_REQUIRED"))
        .oneOf([true], t("REGISTER.FIELD_REQUIRED")),
      insurance: yup.object({
        dateFrom: yup
          .string()
          .nullable()
          .required(t("REGISTER.FIELD_REQUIRED")),
        dateTo: yup.string().nullable().required(t("REGISTER.FIELD_REQUIRED")),
        amount: yup.object({
          value: yup.string().required(t("REGISTER.FIELD_REQUIRED")),
        }),
      }),
      company: yup.object({
        name: yup
          .mixed()
          .test(
            "is-b2b",
            t("REGISTER.FIELD_REQUIRED"),
            (value: string, context) => {
              const req = resolveYupValue(context, "abstractContractType");
              if (req && req == "B2B") {
                return value ? true : false;
              } else {
                return true;
              }
            }
          ),
        nip: yup
          .mixed()
          .test(
            "is-nip",
            t("REGISTER.COMPANY.NIP_INVALID"),
            (value: string, context: TestOptions["options"]) => {
              const req = resolveYupValue(context, "abstractContractType");
              if (req && req == "B2B") {
                if (isValidNip(value)) {
                  return true;
                } else {
                  return false;
                }
              } else {
                return true;
              }
            }
          ),
        address: yup.object({
          street: yup
            .mixed()
            .test(
              "is-b2b",
              t("REGISTER.FIELD_REQUIRED"),
              (value: string, context) => {
                const req = resolveYupValue(context, "abstractContractType");
                if (req && req == "B2B") {
                  return value ? true : false;
                } else {
                  return true;
                }
              }
            ),
          houseNumber: yup
            .mixed()
            .test(
              "is-b2b",
              t("REGISTER.FIELD_REQUIRED"),
              (value: string, context) => {
                const req = resolveYupValue(context, "abstractContractType");
                if (req && req == "B2B") {
                  return value ? true : false;
                } else {
                  return true;
                }
              }
            ),
          postCode: yup
            .mixed()
            .test(
              "is-nip",
              t("REGISTER.COMPANY.POSTCODE_INVALID"),
              (value: string, context: TestOptions["options"]) => {
                const req = resolveYupValue(context, "abstractContractType");
                if (req && req == "B2B") {
                  if (isValidPostcode(value)) {
                    return true;
                  } else {
                    return false;
                  }
                } else {
                  return true;
                }
              }
            ),
          city: yup
            .mixed()
            .test(
              "is-b2b",
              t("REGISTER.FIELD_REQUIRED"),
              (value: string, context) => {
                const req = resolveYupValue(context, "abstractContractType");
                if (req && req == "B2B") {
                  return value ? true : false;
                } else {
                  return true;
                }
              }
            ),
        }),
      }),
      specializations: yup
        .array()
        .of(
          yup.object({
            id: yup.number().required(t("REGISTER.FIELD_REQUIRED")),
            status: yup.string().required(t("REGISTER.FIELD_REQUIRED")),
          })
        )
        .strict(),
    });

    const { errors, meta } = useForm({
      validationSchema: schema,
      initialValues: {
        abstractContractType: undefined,
        contractType: undefined,
        academicTitle: undefined,
        pwz: { number: "" },
        authorityForMedicalCerts: undefined,
        insurance: {
          dateFrom: undefined,
          dateTo: undefined,
          amount: { value: "" },
        },
        company: {
          name: "",
          nip: "",
          address: {
            street: "",
            houseNumber: "",
            postCode: "",
            city: "",
          },
        },
        specializations: [{ id: undefined, status: undefined }],
      },
    });

    const model: Ref<RegisterContractExt> = ref({
      contractType:
        useField<RegisterContractExt["contractType"]>("contractType").value,
      abstractContractType: useField<
        RegisterContractExt["abstractContractType"]
      >("abstractContractType").value,
      academicTitle:
        useField<RegisterContractExt["academicTitle"]>("academicTitle").value,
      pwz: {
        number:
          useField<RegisterContractExt["pwz"]["number"]>("pwz.number").value,
        file: useField<RegisterContractExt["pwz"]["file"]>("pwz.file").value,
      },

      authorityForMedicalCerts: useField<
        RegisterContractExt["authorityForMedicalCerts"]
      >("authorityForMedicalCerts").value,
      insurance: {
        dateFrom:
          useField<RegisterContractExt["insurance"]["dateFrom"]>(
            "insurance.dateFrom"
          ).value,
        dateTo:
          useField<RegisterContractExt["insurance"]["dateTo"]>(
            "insurance.dateTo"
          ).value,
        amount: {
          value: useField<RegisterContractExt["insurance"]["amount"]["value"]>(
            "insurance.amount.value"
          ).value,
          currency: useField<
            RegisterContractExt["insurance"]["amount"]["currency"]
          >("insurance.amount.currency", undefined, {
            initialValue: "PLN",
          }).value,
        },
        file: useField<RegisterContractExt["insurance"]["file"]>(
          "insurance.file"
        ).value,
      },
      company: {
        name: useField<RegisterContractExt["company"]["name"]>("company.name")
          .value,
        nip: useField<RegisterContractExt["company"]["nip"]>("company.nip")
          .value,
        address: {
          street: useField<RegisterContractExt["company"]["address"]["street"]>(
            "company.address.street"
          ).value,
          houseNumber: useField<
            RegisterContractExt["company"]["address"]["houseNumber"]
          >("company.address.houseNumber").value,
          flatNumber: useField<
            RegisterContractExt["company"]["address"]["flatNumber"]
          >("company.address.flatNumber").value,
          postCode: useField<
            RegisterContractExt["company"]["address"]["postCode"]
          >("company.address.postCode").value,
          city: useField<RegisterContractExt["company"]["address"]["city"]>(
            "company.address.city"
          ).value,
        },
      },
      specializations:
        useField<RegisterContractExt["specializations"]>("specializations")
          .value,
    });

    const disabledDateFrom = (current: Moment) => {
      return current && current > moment();
    };

    const disabledDateTo = (current: Moment) => {
      return current && current <= moment().startOf("day");
    };

    const loading: Ref<boolean> = ref(true);
    const submitLoading: Ref<boolean> = ref(false);
    const contractTypes: Ref<{ [key in ContractType]?: ContractType }> = ref(
      {}
    );
    const abstractContractTypes: Ref<{
      [key in AbstractContracType]: AbstractContracType;
    }> = ref({
      B2B: "B2B" as AbstractContracType,
      CONTRACT_FOR_SERVICES: "CONTRACT_FOR_SERVICES" as AbstractContracType,
    });
    const academicTitles: Ref<Array<AcademicTitle>> = ref([]);
    const prev = () => {
      router.push("/register/personal");
    };

    const filterSpecs = () => {
      specializationsOptions.value = specializationsOptions.value.map(
        (specs) => {
          specs = specializations;
          return pickBy(specs, function (specId) {
            return !model.value.specializations
              .map((spec) => spec.id)
              .includes(specId);
          });
        }
      );
    };

    const onChangeSpecs = (currentChangedIndex: number) => {
      filterSpecs();
      const key = findKey(
        specializations,
        (specId) =>
          specId === model.value.specializations[currentChangedIndex].id
      );
      if (key) {
        specializationsOptions.value[currentChangedIndex][key] =
          // eslint-disable-next-line  @typescript-eslint/no-non-null-assertion
          model.value.specializations[currentChangedIndex].id!;
      }
    };

    const formatInsuranceAmount = () => {
      let tmpValue = model.value.insurance.amount.value;

      tmpValue = tmpValue.replace(/[^\d,]+/g, "");
      tmpValue = tmpValue.replace(/(,.*),/g, "$1");
      tmpValue = tmpValue.replace(/,(\d\d)\d?$/, ",$1");

      model.value.insurance.amount.value = tmpValue;
    };

    const onChangeAbstractContractType = (
      value: RegisterContractExt["abstractContractType"]
    ) => {
      if (value === "CONTRACT_FOR_SERVICES") {
        model.value.contractType = null;
      } else {
        useValidateField("contractType");
      }
    };

    const beforeUploadInsuranceFile = (files: Array<File>) => {
      for (const file of files) {
        const isPdf = file.type === "application/pdf";
        if (!isPdf) {
          message.error(t("REGISTER.ONLY_FILE_TYPE", { files: "pdf" }));
        }
        const isLt2M = file.size / 1024 / 1024 < 2;
        if (!isLt2M) {
          message.error(t("REGISTER.SMALLER_THAN", { maxFileSize: "2MB" }));
        }
        if (isPdf && isLt2M) {
          return true;
        }
      }

      return false;
    };

    const beforeUploadPWZFile = (files: Array<File>) => {
      for (const file of files) {
        const isJpgOrPngOrPdf =
          file.type === "image/jpeg" ||
          file.type === "image/png" ||
          file.type === "application/pdf";
        if (!isJpgOrPngOrPdf) {
          message.error(
            t("REGISTER.ONLY_FILE_TYPE", { files: "jpg, png, pdf" })
          );
        }

        const isLt2M = file.size / 1024 / 1024 < 2;
        if (!isLt2M) {
          message.error(t("REGISTER.SMALLER_THAN", { maxFileSize: "2MB" }));
        }
        if (isJpgOrPngOrPdf && isLt2M) {
          return true;
        }
      }

      return false;
    };

    const submit = async () => {
      const reqBody: RegisterContract = {
        contractType: model.value.contractType,
        academicTitle: model.value.academicTitle,
        pwz: {
          number: model.value.pwz.number,
        },
        authorityForMedicalCerts: model.value.authorityForMedicalCerts,
        insurance: {
          dateFrom: model.value.insurance.dateFrom.format("YYYY-MM-DD"),
          dateTo: model.value.insurance.dateTo.format("YYYY-MM-DD"),
          amount: {
            value: Number(model.value.insurance.amount.value.replace(",", ".")),
            currency: model.value.insurance.amount.currency,
          },
        },
        specializations: model.value
          .specializations as RegisterContract["specializations"],
      };
      if (model.value.pwz.file && model.value.pwz.file[0]) {
        reqBody.pwz.file = await fileToBase64(model.value.pwz.file[0]);
        reqBody.pwz.fileName = model.value.pwz.file[0].name;
      }

      if (model.value.insurance.file?.[0]) {
        reqBody.insurance.file = await fileToBase64(
          model.value.insurance.file[0]
        );
        reqBody.insurance.fileName = model.value.insurance.file[0].name;
      }

      if (model.value.contractType) {
        reqBody.company = {
          name: model.value.company.name,
          nip: model.value.company.nip,
          address: { ...model.value.company.address },
        };
        if (!reqBody.company.address.flatNumber) {
          delete reqBody.company.address.flatNumber;
        }
      }
      submitLoading.value = true;
      try {
        await http.put(
          `v1/doctors/${store.state.user.id}/register/contract`,
          reqBody
        );
        store.commit("setUser", {
          ...store.state.user,
          registerStatus: "SPECIALIZATIONS",
        });
        router.push("/register/specializations");
      } catch (e: any) {
        showErrors(e.response?.data);
      } finally {
        submitLoading.value = false;
      }
    };

    const addSpecialization = () => {
      model.value.specializations.push({
        id: undefined,
        status: undefined,
      });
      specializationsOptions.value.push(specializations);
      filterSpecs();
    };

    const deleteSpecialization = (index: number) => {
      model.value.specializations.splice(index, 1);
      specializationsOptions.value.splice(index, 1);
      filterSpecs();
    };

    onMounted(async () => {
      try {
        const responses = await Promise.all([
          http
            .get("v1/doctors/dictionary/contract-types")
            .then((res): Array<ContractType> => res.data),
          http
            .get("v1/doctors/dictionary/academic-titles")
            .then((res): Array<AcademicTitle> => res.data),
          http
            .get("v1/doctors/dictionary/official-specializations")
            .then((res): Specializations => res.data),
        ]);
        contractTypes.value = reduce(
          responses[0],
          (obj: typeof contractTypes.value, param: ContractType) => {
            obj[param] = param;
            return obj;
          },
          {}
        );
        academicTitles.value = responses[1];
        const spacializationsArray = map(responses[2], (value, key) => {
          return {
            title: t(`OFFICIAL_SPECIALIZATION.${key}`),
            value: value,
            key: key,
          };
        }).sort((a, b) => a.title.localeCompare(b.title));
        const spacializationsObj = mapValues(
          keyBy(spacializationsArray, "key"),
          "value"
        );
        specializations = spacializationsObj;
        specializationsOptions.value.push(spacializationsObj);
      } catch (e: any) {
        showErrors(e.response?.data);
      }

      try {
        if (store.state.user.registerStatus !== "CONTRACT") {
          const data = await http
            .get(`v1/doctors/${store.state.user.id}/register/contract`)
            .then((res): RegisterContract => res.data);
          if (!data.contractType) {
            model.value.abstractContractType = "CONTRACT_FOR_SERVICES";
          } else {
            model.value.abstractContractType = "B2B";
          }

          deepIterate<RegisterContract>(data, async (key) => {
            if (key[1] === "dateTo") {
              model.value.insurance.dateTo = moment(
                data.insurance.dateTo,
                "YYYY-MM-DD"
              );
            } else if (key[1] === "dateFrom") {
              model.value.insurance.dateFrom = moment(
                data.insurance.dateFrom,
                "YYYY-MM-DD"
              );
            } else if (key[1] === "amount" && key[2] === "value") {
              model.value.insurance.amount.value = data.insurance.amount.value
                .toFixed(2)
                .replace(".", ",");
              formatInsuranceAmount();
            } else if (key[0] === "pwz" && key[1] === "file") {
              if (
                data.pwz.fileName &&
                data.pwz.fileName !== "null" &&
                data.pwz.file
              ) {
                const file = await dataUrlToFile(
                  data.pwz.file,
                  data.pwz.fileName
                );
                model.value.pwz.file = [file];
              }
            } else if (key[0] === "insurance" && key[1] === "file") {
              if (
                data.insurance.fileName &&
                data.insurance.fileName !== "null" &&
                data.insurance.file
              ) {
                const file = await dataUrlToFile(
                  data.insurance.file,
                  data.insurance.fileName
                );
                model.value.insurance.file = [file];
              }
            } else {
              set(model.value, key, get(data, key));
            }
          });
          for (let i = 1; i <= model.value.specializations.length; i++)
            specializationsOptions.value.push(specializations);
        }
      } finally {
        loading.value = false;
      }
    });

    return {
      errors,
      meta,
      t,
      model,
      prev,
      isValid: computed(() => meta.value.valid),
      loading,
      contractTypes,
      academicTitles,
      disabledDateFrom,
      disabledDateTo,
      beforeUploadInsuranceFile,
      beforeUploadPWZFile,
      abstractContractTypes,
      formatInsuranceAmount,
      submit,
      onChangeAbstractContractType,
      submitLoading,
      addSpecialization,
      deleteSpecialization,
      specializationsOptions,
      pwzRequired,
      onChangeSpecs,
    };
  },
});
export default RegisterContractComponent;
