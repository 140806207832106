import { get } from "lodash";
import { TestOptions } from "yup";

export const resolveYupValue = (
  context: TestOptions["options"],
  path: string
) => {
  const tmpArray = Array.from(context.from || []);
  return get(tmpArray[tmpArray.length - 1].value, path);
};
